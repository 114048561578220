import React, { AnimationEvent, SyntheticEvent } from "react";
import { PageCssTheme } from "../pages/PageCssTheme";
import { Utils } from "../_core/Utils";
import { BodyClassEnum, BodyThemeEnum } from "./Enums";

const theme: BodyThemeEnum = Utils.getTheme();

/**
 * HOC that wraps component that need to display a background image
 * @param Component the component that gets wrapped
 * @param contextClass each page has a different context class applied to it
 * @param hasBackgroundVideo if provided, no background image is set
 */
const SetRandomScreen =
  <T extends object>(
    Component: React.ComponentType<T>,
    contextClass: BodyClassEnum,
    hasBackgroundVideo?: boolean
  ): React.FC<T> =>
  (props) => {
    PageCssTheme.setTheme(theme);
    const imageUrls = PageCssTheme.getBackgroundImages(
      contextClass,
      theme,
      hasBackgroundVideo
    );

    const handleAnimationStart = (event: AnimationEvent) => {
      if (
        event.animationName === "zoom-out-in" ||
        event.animationName === "zoom-in-out"
      ) {
        let idParts = event.currentTarget.id.split("-");
        let imageIndex = parseInt(idParts[1]);
        let nextIndex = (imageIndex + 1) % imageUrls.length;
        let nextElementId = "#image-" + nextIndex;
        let nextElement = document.querySelector(nextElementId);

        if (nextElement) {
          nextElement.classList.remove("image-zoom-in", "image-zoom-out");
        }
      }
    };

    const handleAnimationEnd = (event: AnimationEvent) => {
      if (
        event.animationName === "zoom-out-in" ||
        event.animationName === "zoom-in-out"
      ) {
        event.currentTarget.classList.add("image-invisible");

        let idParts = event.currentTarget.id.split("-");
        let imageIndex = parseInt(idParts[1]);
        let nextIndex = (imageIndex + 1) % imageUrls.length;
        let nextElementId = "#image-" + nextIndex;
        let nextElement = document.querySelector(nextElementId);

        if (nextElement) {
          nextElement.classList.remove("image-invisible");
          let className =
            nextIndex % 2 === 0 ? "image-zoom-in" : "image-zoom-out";
          nextElement.classList.add(className);
        }
      }
    };

    return (
      <React.Fragment>
        <section className="animation-container">
          {imageUrls &&
            imageUrls.map((img, index) => (
              <div
                key={"image" + index}
                id={"image-" + index}
                className={index === 0 ? "image-zoom-in" : "image-invisible"}
                style={{
                  backgroundImage: `url(${img})`,
                  backgroundSize: hasBackgroundVideo ? null : "cover",
                }}
                onAnimationEnd={handleAnimationEnd}
                onAnimationStart={handleAnimationStart}
              ></div>
            ))}
        </section>
        <Component {...(props as T)} />
      </React.Fragment>
    );
  };

export default SetRandomScreen;
