import React, { useState } from "react";
import TranslationContext from "../../TranslationContext";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface IEnterPasswordProps {
  password: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  /**
   * Placeholder for the input field. Optional, defaults to translations.placeholderEnterPassword
   */
  placeholder?: string;
  showInvalidIcon?: boolean;
  showValidIcon?: boolean;
  onBlur?: () => void;
  tabIndex: number;
  autofocus?: boolean;
  showPasswordButton?: boolean;
}


const useStyles = makeStyles({
  input:{
  "&::-ms-reveal":{
    display: "none"
  },
  "&::-ms-clear":{
    display: "none"
  }}
});

const EnterPassword: React.FunctionComponent<IEnterPasswordProps> = (props) => {
  const { showInvalidIcon, showPasswordButton, showValidIcon, placeholder } =
    props;

  const translations = React.useContext(TranslationContext);
  const { onEnter, tabIndex, autofocus, onChange } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const showIcon = showInvalidIcon || showPasswordButton || showValidIcon;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.nativeEvent.code === "Enter" && onEnter) {
        onEnter();
      }
    },
    [onEnter]
  );

  const toggleShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const classes = useStyles();

  return (
    <div className={showIcon ? "form-item has-icon" : "form-item"}>
      <input
        value={props.password}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={props.onBlur}
        type={passwordShown ? "text" : "password"}
        placeholder={placeholder || translations.placeholderEnterPassword}
        tabIndex={tabIndex}
        autoFocus={autofocus}
        className={classes.input}
      />

      {showIcon && (
        <div className="form-item-input-icon">
          {showPasswordButton && !passwordShown && (
            <VisibilityOutlined onClick={toggleShowPassword} opacity={0.54} />
          )}
          {showPasswordButton && passwordShown && (
            <VisibilityOffOutlined
              onClick={toggleShowPassword}
              opacity={0.54}
            />
          )}
          {showInvalidIcon && (
            <IoIosCloseCircleOutline
              width="34px"
              height="34px"
              color="#e22626"
            />
          )}
          {showValidIcon && (
            <IoIosCheckmarkCircleOutline
              width="34px"
              height="34px"
              color="#1c941c"
            />
          )}
        </div>
      )}
    </div>
  );
};
export default EnterPassword;
