import React from "react";
import PageFooter from "../components/PageBody/PageFooter";
import { Link } from "react-router-dom";
import TranslationContext from "../components/TranslationContext";
import { BodyClassEnum } from "../common/Enums";
import SetRandomScreen from "../common/SetRandomScreen";
import Header from "../components/PageBody/Parts/Header";

const Locked: React.FunctionComponent = () => {
  return (
    <div id="content-container">
      <section className="content">
        <Header
          headerResourceKey="captionLockedAfterTooManyFailedAttempts"
          textResourceKey="descriptionLockedAfterTooManyFailedAttempts"
        />
        <PageFooter
          additionalFooterContent={<AdditionalFooterContent />}
          useStackedLinks={true}
        />
      </section>
    </div>
  );
};

const AdditionalFooterContent: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const linkForgotPassword = "/forgotpassword" + window.location.search;
  const linkLogin = "/" + window.location.search;

  return (
    <div className="link-login forgotten">
      <Link to={linkForgotPassword}>
        <button className="btn btn-link" type="button">
          {translations.labelForgotPassword}
        </button>
      </Link>
      <Link to={linkLogin}>
        <button className="btn btn-link" type="button">
          {translations.labelGotoLogin}
        </button>
      </Link>
    </div>
  );
};

export default SetRandomScreen(
  Locked,
  BodyClassEnum.UserLockedAfterTooManyFailedAttempts
);
