/**
 * Enum holding the context classes applied to each component/ screen
 */
export enum BodyClassEnum {
  UnknownUser = "unknownUser",
  WrongPasswordUnknownUser = "wrongPasswordUnknownUser",
  UserLockedAfterTooManyFailedAttempts = "userLockedAfterTooManyFailedAttempts",
  Unlock = "unlock",
  EmailVerification = "emailVerification",
  UserLogOff = "userLogoff",
  ForgotPassword = "forgotPassword",
  ExpiredLink = "expiredLink",
  ResetPassword = "resetPassword",
  FallbackLandingPage = "fallbackLandingPage",
}

export enum BodyThemeEnum {
  Dark = "dark",
  Light = "light",
}
