import { BodyClassEnum, BodyThemeEnum } from "../common/Enums";
import { Settings } from "../_core/settings/Settings";

// variable to check if we're in development mode
const mode: string = process.env.REACT_APP_MODE;

export class PageCssTheme {
  static getImageUrls = (
    contextClass: BodyClassEnum,
    theme: BodyThemeEnum,
    hasAnimatedBackground?: boolean
  ): string[] => {
    const screenResolution = "1920";
    const pathToServerFolder: string =
      mode === "development" ? `${Settings.idsUrl}/images` : "/images-login";

    if (hasAnimatedBackground) {
      // TODO (in one of the next rounds...): Get urls from server

      return [
        `${pathToServerFolder}/background/${theme}/${screenResolution}/${contextClass}/unknownUser1.jpg`,
        `${pathToServerFolder}/background/${theme}/${screenResolution}/${contextClass}/unknownUser2.jpg`,
        `${pathToServerFolder}/background/${theme}/${screenResolution}/${contextClass}/unknownUser3.jpg`,
      ];
    } else {
      return [
        `${pathToServerFolder}/background/${theme}/${screenResolution}/${contextClass}.jpg`,
      ];
    }
  };

  static getBackgroundImages(
    contextClass: BodyClassEnum,
    theme: BodyThemeEnum,
    hasAnimatedBackground?: boolean
  ): string[] {
    const imageUrls = this.getImageUrls(
      contextClass,
      theme,
      hasAnimatedBackground
    );

    return imageUrls;
  }

  static setBackgroundImage(contextClass: BodyClassEnum, theme: BodyThemeEnum) {
    const imageUrls = this.getImageUrls(contextClass, theme, false);

    const backgroundImageContainer = document.querySelector(
      ".animation-container"
    );
    while (backgroundImageContainer.firstChild) {
      backgroundImageContainer.removeChild(backgroundImageContainer.firstChild);
    }
    var newNode = document.createElement("div");
    newNode.style.backgroundImage = `url(${imageUrls[0]})`;
    newNode.style.backgroundSize = "cover";
    backgroundImageContainer.append(newNode);
  }

  static setTheme(theme: BodyThemeEnum) {
    const bodySelector = document.querySelector("body");
    bodySelector.classList.add(theme);
  }
}
