import React from "react";
import { PasswordActions } from "../_core/auth/PasswordActions";
import EnterPassword from "../components/PageBody/Parts/EnterPassword";
import PageFooter from "../components/PageBody/PageFooter";
import { Settings } from "../_core/settings/Settings";
import { VerificationTypeEnum } from "../_core/models/VerificationTypeEnum";
import TranslationContext from "../components/TranslationContext";
import { Utils } from "../_core/Utils";
import SetRandomScreen from "../common/SetRandomScreen";
import { BodyClassEnum } from "../common/Enums";
import { IPasswordResetResult } from "../_core/models/IPasswordResetResult";
import Header from "../components/PageBody/Parts/Header";
import { PasswordPolicy } from "../_core/auth/PasswordPolicy";
import PasswordPolicyComponent from "../components/PageBody/Parts/PasswordPolicy";

interface IViewModel {
  headerResourceKey: string;
  textResourceKey: string;
  codeIsVerified: boolean;
  showForm: boolean;
  showPasswordPolicy: boolean;
  userName: string;
}

const ResetPassword: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const [password1, setPassword1] = React.useState<string>("");
  const [password2, setPassword2] = React.useState<string>("");

  /**
   * Password validation starts after user leaves input field for password 1.
   */
  const [password1ValidationStarted, setPassword1ValidationStarted] =
    React.useState<boolean>(false);

  const [viewModel, setViewModel] = React.useState<IViewModel>({
    headerResourceKey: "captionHeyUsername",
    textResourceKey: "captionResetPassword",
    codeIsVerified: false,
    showForm: true,
    showPasswordPolicy: true,
    userName: "",
  });

  /**
   * Extracts the validationCode and validationType from the pathname.
   * Path: /ResetPassword/[validationType/]validationCode
   * The validationType is optional in the path and defaults to VerificationTypeEnum.PasswordReset
   */
  const getVerificationInfoFromUrl = (): [string, VerificationTypeEnum] => {
    var pathnameElements = window.location.pathname.split("/");
    var verificationCode = pathnameElements.pop() || "";
    var verificationTypePart = pathnameElements.pop();
    let verificationType = VerificationTypeEnum.PasswordReset; // as default
    if (!isNaN(Number(verificationTypePart))) {
      const asType = Number(verificationTypePart) as VerificationTypeEnum;
      if (asType === VerificationTypeEnum.PasswordExpired) {
        verificationType = VerificationTypeEnum.PasswordExpired;
      } else if (asType === VerificationTypeEnum.EmailChanged) {
        verificationType = VerificationTypeEnum.EmailChanged;
      } else if (asType === VerificationTypeEnum.VerifyUser) {
        verificationType = VerificationTypeEnum.VerifyUser;
      }
    }
    return [verificationCode, verificationType];
  };

  React.useEffect(() => {
    const [verificationCode, verificationType] = getVerificationInfoFromUrl();
    PasswordActions.getVerificationCodeValidation(
      verificationCode,
      verificationType
    ).then((result) => {
      if (result.isValid) {
        const caption =
          verificationType === VerificationTypeEnum.PasswordExpired
            ? "captionResetExpiredPassword"
            : "captionResetPassword";
        setViewModel((previousState) => {
          return {
            ...previousState,
            textResourceKey: caption,
            codeIsVerified: true,
            userName: result.userName,
            showPasswordPolicy: true,
          };
        });
      } else {
        window.location.href =
          "/ExpiredLink?RedirectUrl=" +
          Utils.getRedirectUrlFromLocation(window.location);
      }
    });
  }, [
    translations.captionResetPassword,
    translations.captionResetExpiredPassword,
  ]); // translation strings included to avoid error; wouldn't be necessary react wise.

  const password1IsValid =
    password1ValidationStarted && PasswordPolicy.check(password1);
  const password2IsValid =
    password1ValidationStarted &&
    PasswordPolicy.check(password2) &&
    password1 === password2;

  const handleBlurPassword1 = () => {
    if (password1 && !password1ValidationStarted) {
      setPassword1ValidationStarted(true);
    }
  };

  const handlePasswordChangeResult = (
    passwordResetResult: IPasswordResetResult
  ) => {
    if (!passwordResetResult.success) {
      if (passwordResetResult.policyViolation) {
        setViewModel((previousState) => {
          return {
            ...previousState,
            textResourceKey: "captionResetPasswordPolicyViolation",
          };
        });
      }

      if (passwordResetResult.historyViolation) {
        setViewModel((previousState) => {
          return {
            ...previousState,
            textResourceKey: "captionResetPasswordHistoryViolation",
          };
        });
      }
    } else if (
      passwordResetResult.success &&
      Utils.isValidHttpUrl(passwordResetResult.redirectUrl)
    ) {
      setViewModel((previousState) => {
        return {
          ...previousState,
          headerResourceKey: "captionGreatJobUsername",
          textResourceKey: "captionResetPasswordSuccess",
          showForm: false,
          showPasswordPolicy: false,
        };
      });

      setTimeout(() => {
        window.location.href = passwordResetResult.redirectUrl;
      }, Settings.redirectDelay);
    } else {
      window.location.href = "/error";
    }
  };

  async function saveChangedPassword() {
    const [verificationCode, verificationType] = getVerificationInfoFromUrl();
    const redirectUrl =
      verificationType === VerificationTypeEnum.PasswordExpired
        ? Utils.getReturnUrlFromLocation(window.location, false) // expired password does not have a redirect URL directly. Instead the base (Return) URL will be verified.
        : Utils.getRedirectUrlFromLocation(window.location);

    PasswordActions.resetPassword(
      verificationCode,
      verificationType,
      password1,
      redirectUrl
    ).then((passwordResetResult) => {
      handlePasswordChangeResult(passwordResetResult);
    });
  }

  const classNames =
    "content multi-input-field password-reset" +
    (viewModel.showPasswordPolicy ? " password-policy" : "");

  if (!viewModel.codeIsVerified) {
    return (
      <div id="content-container">
        <section className="content multi-input-field"></section>
      </div>
    );
  } else {
    return (
      <div id="content-container">
        <section className={classNames}>
          <Header
            headerResourceKey="captionHeyUsername"
            textResourceKey={viewModel.textResourceKey}
            replacePlaceHolder={(source) => {
              return source.replace("{username}", viewModel.userName);
            }}
          />
          {viewModel.showPasswordPolicy && <PasswordPolicyComponent />}
          {viewModel.showForm && (
            <div className="form">
              <div className="form-items">
                <div className="action-hint">
                  {translations.labelEnterNewPassword}
                </div>
                <EnterPassword
                  password={password1}
                  onChange={setPassword1}
                  showInvalidIcon={
                    password1ValidationStarted && !password1IsValid
                  }
                  showValidIcon={password1ValidationStarted && password1IsValid}
                  onBlur={handleBlurPassword1}
                  tabIndex={1}
                />
                <EnterPassword
                  password={password2}
                  onChange={setPassword2}
                  onEnter={saveChangedPassword}
                  placeholder={translations.placeholderConfirmPassword}
                  showInvalidIcon={
                    password1ValidationStarted &&
                    !!password2 &&
                    !password2IsValid
                  }
                  showValidIcon={
                    password1ValidationStarted &&
                    !!password2 &&
                    password2IsValid
                  }
                  tabIndex={2}
                />
              </div>
              <div className="action">
                <button
                  className="btn btn-login"
                  type="button"
                  tabIndex={3}
                  onClick={saveChangedPassword}
                  disabled={
                    !password1ValidationStarted ||
                    !password1IsValid ||
                    !password2IsValid
                  }
                >
                  {translations.labelReset}
                </button>
              </div>
            </div>
          )}
          <PageFooter />
        </section>
      </div>
    );
  }
};

export default SetRandomScreen(ResetPassword, BodyClassEnum.ResetPassword);
