import React from "react";
import TranslationContext from "../TranslationContext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { dataPolicyDE, dataPolicy } from "../../static/dataPolicy";
import { marked } from "marked";

interface IPageFooterProps {
  additionalFooterContent?: React.ReactElement;
  useStackedLinks?: boolean;
}

const useStyles = makeStyles({
  helpDialog: {
    backgroundImage: "url('images/bg-help.jpg')",
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "fill",
    padding: 0,
    minHeight: "50vh",
  },
});

const PageFooter: React.FunctionComponent<IPageFooterProps> = (props) => {
  const { additionalFooterContent, useStackedLinks } = props;

  return (
    <footer className={useStackedLinks ? "links stacked" : "links"}>
      <FairmasLinks />
      {additionalFooterContent}
    </footer>
  );
};

const FairmasLinks: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const [dialog, setDialog] = React.useState<
    "none" | "help" | "imprint" | "dataPolicy"
  >("none");
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className="link-fairmas">
        <button
          className={`btn btn-link`}
          type="button"
          tabIndex={10}
          onClick={() => {
            setDialog("help");
          }}
        >
          {translations.labelHelp}
        </button>
        <button
          className={`btn btn-link`}
          type="button"
          tabIndex={11}
          onClick={() => {
            setDialog("imprint");
          }}
        >
          {translations.labelImprint}
        </button>

        <button
          className={`btn btn-link`}
          type="button"
          tabIndex={12}
          onClick={() => {
            setDialog("dataPolicy");
          }}
        >
          {translations.labelDataPolicy}
        </button>
      </div>

      <Dialog
        maxWidth={"xl"}
        onClose={() => {
          setDialog("none");
        }}
        open={dialog === "imprint"}
        scroll={"body"}
        className="fms-dialog"
      >
        <DialogContent dividers>
          <Box p={8}>
            <Typography variant={"h1"} color={"textPrimary"}>
              {translations.labelImprint}
            </Typography>

            <Grid container spacing={4}>
              <Grid item md={4}>
                <Typography variant={"h6"}>
                  {translations.labelIntroduction}
                </Typography>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelCompany}:
                  </Typography>
                  <Typography variant={"body1"}>
                    {translations.descriptionCompany}
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelAddress}:
                  </Typography>
                  <Typography variant={"body1"}>
                    {translations.descriptionAddress}
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.captionContact}:
                  </Typography>

                  <Typography variant={"body1"}>
                    {translations.labelTelephone}: +49 30 322 940 5 20{" "}
                  </Typography>

                  <Typography variant={"body1"}>
                    {translations.labelFax}: +49 30 322 940 5 21{" "}
                  </Typography>

                  <Typography variant={"body1"}>
                    {translations.labelWeb}:{" "}
                    <a className="link" href="http://www.fairmas.com">
                      www.fairmas.com
                    </a>
                  </Typography>

                  <Typography variant={"body1"}>
                    {translations.labelEmail}:{" "}
                    <a className="link" href="mailto:office@fairmas.com">
                      office@fairmas.com
                    </a>
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelLegalRepresentative}:
                  </Typography>
                  <Typography variant={"body1"}>
                    {translations.descriptionLegalRepresentative}
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelCommercialRegister}:
                  </Typography>
                  <Typography variant={"body1"}>
                    {translations.descriptionCommercialRegister}
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"body1"}>
                    {translations.labelVat}:
                  </Typography>
                  <Typography variant={"body1"}>DE231831626</Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelDataProtectionOfficial}:
                  </Typography>
                  <Typography variant={"body1"}>
                    <a href="http://www.itdsb.de/">
                      IT.DS Beratung – Sven Meyzis
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={8}>
                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelLegalHint}
                  </Typography>
                  <Typography variant={"body1"}>
                    {translations.descriptionLegalHint.replace(
                      "{0}",
                      window.location.hostname
                    )}
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"h5"} color={"textPrimary"}>
                    {translations.labelUsedComponents}
                  </Typography>
                  <Typography variant={"body1"}>
                    {translations.descriptionMITLicenseComponents}
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"body1"}>
                    Copyright &copy; 2013-{new Date().getFullYear()}{" "}
                    <a href="http://ionicframework.com">
                      http://ionicframework.com
                    </a>
                    /
                  </Typography>
                  <Typography variant={"body1"}>
                    Copyright &copy; {new Date().getFullYear()}{" "}
                    <a href="https://fortawesome.github.io/Font-Awesome/">
                      https://fortawesome.github.io/Font-Awesome/
                    </a>
                  </Typography>
                </Box>

                <Box pt={1}>
                  <Typography variant={"body1"}>
                    {translations.descriptionMITLicensePart1}
                  </Typography>
                  <br />
                  <Typography variant={"body1"} color={"textPrimary"}>
                    {translations.descriptionMITLicensePart2}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"inherit"}
            onClick={() => {
              setDialog("none");
            }}
            autoFocus
          >
            {translations.labelClose}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"xl"}
        onClose={() => {
          setDialog("none");
        }}
        open={dialog === "dataPolicy"}
      >
        <DialogContent dividers>
          <Box p={8}>
            <Typography
              variant={"body1"}
              dangerouslySetInnerHTML={{
                __html: marked(
                  navigator.language.startsWith("de")
                    ? dataPolicyDE
                    : dataPolicy
                ),
              }}
            ></Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"inherit"}
            onClick={() => {
              setDialog("none");
            }}
            autoFocus
          >
            {translations.labelClose}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {
          setDialog("none");
        }}
        open={dialog === "help"}
        className="fms-dialog"
      >
        <DialogContent className={classes.helpDialog} dividers={true}>
          <Box p={8} width={"30%"}>
            <Typography variant={"h1"} color={"textPrimary"}>
              {translations.labelHelp}
            </Typography>
            <br />
            <Typography variant={"h6"}>
              {translations.labelIntroductionPart1}
            </Typography>
            <br />
            <Typography variant={"h6"}>
              {translations.labelIntroductionPart2}
            </Typography>
            <br />
            <Typography variant={"h6"} color={"textPrimary"}>
              {translations.labelUsingPhone}
            </Typography>
            <Typography variant={"h6"}>+49 30 322 940 5 20</Typography>
            <br />
            <Typography variant={"h6"} color={"textPrimary"}>
              {translations.labelUsingEmail}
            </Typography>
            <Typography variant={"h6"}>fairplanner@fairmas.com</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"inherit"}
            onClick={() => {
              setDialog("none");
            }}
            autoFocus
          >
            {translations.labelClose}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PageFooter;
