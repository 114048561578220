import { ILogoutModel } from '../models/ILogoutModel';
import axios from "axios";
import { Settings } from "../settings/Settings";

export class Logout {
  static async logout(logoutId: string): Promise<
    any
  > {
    var model: ILogoutModel = {
      logoutId
    }

    var response = await axios.post<string>(
      Settings.idsUrl + "/api/Logout",
      model,
      { withCredentials: true }
    );

    return response.data;
  }
}