import axios from "axios";
import { Settings } from "../settings/Settings";
import { IVerifyNewEmailModel } from '../models/IVerifyNewEmailModel';
import { IUserEmailVerificationVerifyResult } from '../models/IUserEmailVerificationVerifyResult';

export class VerifyNewEmail {
  static async verify(verificationCode: string, redirectUrl: string): Promise<
    IUserEmailVerificationVerifyResult
  > {
    var model: IVerifyNewEmailModel = {
      verificationCode,
      redirectUrl
    }

    var response = await axios.post(
      Settings.idsUrl + "/api/VerifyNewEmail",
      model,
      { withCredentials: true }
    );

    return response.data;
  }

}
