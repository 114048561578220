import { IUnlockUserModel } from './../models/IUnlockUserModel';
import { IUserUnlockResult } from './../models/IUserUnlockResult';
import axios from "axios";
import { Settings } from "../settings/Settings";

export class UnlockUser {
  static async unlock(verificationCode: string, redirectUrl: string): Promise<
    IUserUnlockResult
  > {
    var model: IUnlockUserModel = {
      verificationCode,
      redirectUrl
    }

    var response = await axios.post(
      Settings.idsUrl + "/api/UnlockUser",
      model,
      { withCredentials: true }
    );

    return response.data;
  }

}
