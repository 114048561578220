import React from "react";
import { ArrowBack } from "@material-ui/icons";
import { IconButton, makeStyles } from "@material-ui/core";
import { Utils } from "../../../_core/Utils";
import { BodyThemeEnum } from "../../../common/Enums";
import "../../../sass/_vars.scss"

const lightOrDarkCondition: BodyThemeEnum = Utils.getTheme();

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
  },
  root:{
    padding: "4px"
  }  ,
  backIcon: {
    fill: "#f7f7f7",
    backgroundColor: "#df6b21",
    borderRadius: "4px",    
    padding: 0
  },
  backButton:{
    marginRight: "12px",
    fill: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    borderRadius: "4px",    
    padding: 0
  }
});

interface IDisplayInputProps {
  text: string;
  tabIndex: number;
  onHandleBackClick: () => void;
}

const DisplayOnlyInput: React.FunctionComponent<IDisplayInputProps> = (
  props
) => {
  const { text, tabIndex, onHandleBackClick } = props;
  const classes = useStyles(lightOrDarkCondition);

  return (
    <div className="form-item">      
        <IconButton className={classes.backButton} onClick={onHandleBackClick}>
          <ArrowBack classes={{ root: classes.backIcon }}></ArrowBack>
        </IconButton>
        <input
          id="email"
          value={text}
          name="email"
          type="email"
          tabIndex={tabIndex}
          readOnly={true}
          className="readonly"
          autoComplete={"username"}
        />      
    </div>
  );
};

export default DisplayOnlyInput;
