export const dataPolicy = `
# Data Protection Policy Template for Websites with Closed User Groups

## Table of Contents
1 General Information  
1.1	Objective and Responsibility   
1.2	Legal Bases  
1.3	Data Subject Rights  
1.4	Data Erasure and Storage Duration  
1.5	Security of Processing  
1.6	Data Transfers to Third Parties, Subcontractors and Third Party Providers  
2 Concrete Data Processing  
2.1	Collection of Information on the Use of the Online Service  
2.2	Microsoft Application Insights  
2.3	Contact Form and Contact via Email  
3 Cookie Policy  
3.1	General Information  
3.2	Possibilities of Objection  
4 Changes to the Data Protection Policy

**1	General Information**  
***1.1	Objective and Responsibility***
1.	This Data Protection Policy is to inform you about the nature, scope and purpose of the processing of personal data in relation to our online service and the associated websites, features and contents (hereinafter collectively referred to as "online service" or "website").
2.	The provider of the online service and responsible for the data protection law is Fairmas GmbH (EUREF-Campus 13, 10829 Berlin, Germany) - hereinafter referred to as "provider", "we", “our” or "us".
3.	Our online service is made available by 1 & 1 Internet SE, Elgendorfer Str. 57, 56410 Montabaur.
4.	Our Data Protection Officer can be contacted via the email address: datenschutz@fairmas.com (Data Protection Officer: IT.DS Beratung).
5.	The term "user" or “you” encompasses all customers, interested people, employees and visitors of our online service.

***1.2	Legal Bases***  
We collect and process personal data based on the following legal grounds:  
a.	Consent in accordance with Article 6 paragraph 1 (a) General Data Protection Regulation (GDPR). Consent meaning any freely given, specific, informed and unambiguous indication of agreement, which could be in the form of a statement or any other unambiguous confirmatory act, given by the data’s subject consenting to the processing of personal data relating to him or her.  
b.	Necessity for the performance of a contract or in order to take steps prior to entering into a contract according to Article 6 paragraph 1 (b) GDPR, meaning the data is required in order for us to fulfil our contractual obligations towards you or to prepare the conclusion of a contract with you.  
c.	Processing to fulfil a legal obligation in accordance with Article 6 paragraph 1 (c) GDPR, meaning that e.g. the processing of data is required by law or other provisions.  
d.	Processing in order to protect legitimate interests in accordance with Article 6 paragraph 1 (f) GDPR, meaning that the processing is necessary to protect legitimate interests pursued by us or by a third party, unless such interests are overridden by your interests or fundamental rights and freedoms which require the protection of personal data.

***1.3	Data Subject Rights***  
You have the following rights with regards to the processing of your data through us:
a. Right of access in accordance with Article 15 GDPR  
b. Right to rectification in accordance with Article 16 GDPR  
c. Right to erasure („right to be forgotten“) in accordance with Article 17 GDPR  
d. Right to restriction of processing in accordance with Article 18 GDPR  
e. Right to data portability in accordance with Article 20 GDPR  
f. Right to objection in accordance with Article 21 GDPR

Note: Users can object to the processing of their personal data in accordance to the legal requirements at any time with effect for the future. The objection can be lodged in particular against processing for direct marketing purposes.
Without prejudice to any other administrative or judicial remedy, you have the right to complain to a supervisory authority, in particular in the Member State where you are staying, working or suspected of infringing, if you believe that the processing of personal data concerning you is contrary to the GDPR.

***1.4	Data Erasure and Storage Duration***  
The personal data of the data subject will be erased or blocked as soon as the purpose of the storage is deleted. In addition, such storage may take place if provided for by the European or national legislator in EU regulations, laws or other regulations to which the controller is subject. Blocking or erasure of the data also takes place when a storage period prescribed by the standards mentioned expires, unless there is a need for further storage of the data for conclusion of a contract or fulfillment of the contract.

***1.5	Security of Processing***
1.	We have implemented appropriate and state-of-the-art technical and organizational security measures (TOMs). Thus, the data processed by us are protected against accidental or intentional manipulation, loss, destruction and unauthorized access.
2.	The security measures include in particular the encrypted transfer of data between your browser and our server.

***1.6	Data Transfers to Third Parties, Subcontractors and Third Party Providers***
1.	A transfer of personal data to third parties only takes place within the scope of legal requirements. We only disclose users' data to third parties, when necessary, e.g. for billing purposes or other purposes when the transfer is required to fulfill contractual obligations towards the users.
2.	If we use subcontractors for our online service,   we have made appropriate contractual arrangements as well as adequate technical and organizational measures with these companies.
3.	If we use content, tools or other means from other companies (hereinafter collectively referred to as "third party providers") whose registered offices are located in a third country, it is assumed that a transfer of data to the home countries of these third party providers occurs. The transfer of personal data to third countries takes place exclusively only, if an adequate level of data protection, the user’s consent or another legal permission is present.

**2	Concrete Data Processing**  
***2.1	Collection of Information on the Use of the Online Service***
1.	When using our online service, information may be transferred automatically from the browser of the user to us; this information includes the name of the accessed website, file, date and time of the access, amount of data transferred, notification about successful access, browser type and version, the user's operating system, referrer URL (the previously visited page), IP address and the requesting provider.
2.	The processing of this information takes place based on legitimate interests in accordance with Article 6 paragraph 1 (f) GDPR (e.g. to optimize the online service) as well as to ensure the security of processing in accordance with Article 5 paragraph 1 (f) GDPR (e.g. for the defense and clarification purposes of cyberattacks).
3.	The information is automatically deleted 4 weeks after the end of the connection - i. e. use of the online service - provided there are no other retention periods.
4.	The collection of the data and the storage of the data in log files is absolutely necessary for the provision of the online service. Therefore, the user has no possibility of erasure, objection or correction.
5.	The information of the employees about the processing of personal data in the context of the use of Fairmas Online products is incumbent on the respective Fairmas customer in his function as employer.

***2.2	Microsoft Application Insights***
1.	Our web applications use Azure Application Insights, a service from Microsoft that helps us optimize the performance and usability of our applications. It monitors the application as it runs, and creates charts and tables that tell you, for example, what times of day it is in use or how well the app responds. In the event of crashes, errors, or performance issues, you can help telemetry data to determine the causes of errors. During app execution, Application Insights monitors operation and sends telemetry data to an Application Insights service (a cloud service hosted by Microsoft Azure).
2.	The majority of standard telemetry (i.e. telemetry sent without writing code) does not contain explicit personal information. However, it may be possible to draw conclusions about individual persons from an event list.
3.	For more information, see the Microsoft Application Insights privacy policy https://docs.microsoft.com/en-us/azure/application-insights/app-insights-data-retention-privacy

***2.3	Contact Form and Contact via Email***
1.	When contacting us (via online form or email), the data provided by the user will be processed exclusively for processing the inquiry and its handling.
2.	Any other use of the data is only based on the consent of the user.
3.	User data are stored in our Customer Relationship Management System ("CRM System") or a comparable software / database. The legal storage periods for business letters apply.

**3	Cookie Policy**  
***3.1	General Information***
1.	Cookies are information transmitted by our web server or third-party web servers to the users' web browsers where they are stored for later retrieval. Cookies can be in the form of small files or any other types of information storage.
2.	If users do not want cookies stored on their computer, they will be asked to disable the option in their browser's system settings. Saved cookies can be deleted in the system settings of the browser. The exclusion of cookies can lead to functional restrictions of this online offer.

***3.2	Possibilities of Objection***
You can object to the use of cookies, which are used for measuring the range of coverage and advertising purposes, via
a.	Deactivation page of the Network Advertising Initiative: [http://optout.networkadvertising.org/](http://optout.networkadvertising.org/)  
b.	The US-American website: [http://www.aboutads.info/choices](http://www.aboutads.info/choices)  
c.	The European website [http://www.youronlinechoices.com/uk/your-ad-choices/](http://www.youronlinechoices.com/uk/your-ad-choices/)

**4	Changes to the Data Protection Policy**
1.	We reserve the right to change this data protection policy in relation to data processing, in order to adapt it to changed legal situations, to changes in the online service or to data processing.
2.	If user consents are required or components of the data protection policy contain provisions of the contractual relationship with the users, the changes will only be made with the users' consent.
3.	Users are requested to inform themselves regularly about the content of this privacy policy

As of: 29.05.2018
`

export const dataPolicyDE = `
# Datenschutzerklärung für die Webseiten mit geschlossenem Nutzerkreisen i.E.

## Inhaltsverzeichnis
1 Allgemeine Angaben  
1.1 Zielsetzung und Verantwortlichkeit  
1.2 Rechtsgrundlagen  
1.3 Betroffenenrechte  
1.4 Datenlöschung und Speicherdauer  
1.5 Sicherheit der Verarbeitung  
1.6 Datenübermittlung an Dritte, Subunternehmer und Drittanbieter  
2 Konkrete Datenverarbeitung  
2.1 Erhebung von Informationen zur Nutzung des Online-Angebotes  
2.2 Microsoft Application Insights  
2.3 Kontaktformular und Kontaktaufnahme per EMail  
3 Cookie-Policy  
3.1 Allgemeine Informationen  
3.2 Widerspruchsmöglichkeiten  
4 Änderungen der Datenschutzerklärung

**1 Allgemeine Angaben**  
***1.1 Zielsetzung und Verantwortlichkeit***
1. Diese Datenschutzerklärung informiert Sie über die Art, den Umfang und den Zweck der Verarbeitung von personenbezogenen Daten in Bezug auf unser Onlineangebot und der damit verbundenen Webseiten, Funktionen und Inhalte (nachfolgend gemeinsam bezeichnet als „Online-Angebot“ oder „Website“).
2. Anbieter des Online-Angebotes und datenschutzrechtlich verantwortlich ist die **Fairmas GmbH** (EUREF-Campus 13, 10829 Berlin, Deutschland) - nachfolgend bezeichnet als „AnbieterIn“, „wir“ oder „uns“.
3. Unser Online-Angebot wird bereitgestellt von der 1&1 Internet SE, Elgendorfer Str. 57, 56410 Montabaur.
4. Unser Datenschutzbeauftragter ist unter der EMail-Adresse datenschutz@fairmas.com (Datenschutzbeauftragter: IT.DS Beratung) erreichbar.
5. Der Begriff „Nutzer“ umfasst alle Kunden und Besucher dieses Online-Angebotes.

***1.2 Rechtsgrundlagen***  
Wir erheben und verarbeiten personenbezogene Daten basierend auf den folgenden Rechtsgrundlagen:  
a. Einwilligung gemäß Artikel 6 Absatz 1 lit. a Datenschutzgrundverordnung (DSGVO). Eine Einwilligung ist jede freiwillige für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass Sie mit der Verarbeitung der Sie betreffenden personenbezogenen Daten einverstanden ist.  
b. Erforderlichkeit zur Vertragserfüllung oder Durchführung vorbereitender Maßnahmen gemäß Artikel 6 Absatz 1 lit. b DSGVO, d.h. die Daten sind erforderlich damit wir die vertraglichen Pflichten ihnen gegenüber erfüllen können oder wir benötigen die Daten um einen Vertragsschluss mit Ihnen vorzubereiten.  
c. Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung gemäß Artikel 6 Absatz 1 lit c DSGVO, d.h. dass z.B. aufgrund eines Gesetzes oder sonstiger Vorschriften eine Verarbeitung der Daten vorgeschrieben ist.  
d. Verarbeitung zur Wahrung berechtigter Interessen gemäß Artikel 6 Absatz 1 lit. f DSGVO, d.h. dass die Verarbeitung erforderlich ist, um berechtigte Interessen unsererseits oder Dritter zu wahren, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten Ihrerseits, die den Schutz personenbezogener Daten erfordern, überwiegen.

***1.3 Betroffenenrechte***  
Ihnen stehen die nachfolgenden Rechte hinsichtlich der Datenverarbeitung durch uns zu:  
a. Recht auf Auskunft gemäß Artikel 15 DSGVO  
b. Recht auf Berichtigung gemäß Artikel 16 DSGVO  
c. Recht auf Löschung („Recht auf Vergessenwerden“) gemäß Artikel 17 DSGVO  
d. Recht auf Einschränkung der Verarbeitung gemäß Artikel 18 DSGVO  
e. Recht auf Datenübertragbarkeit gemäß Artikel 20 DSGVO  
f. Recht auf Widerspruch gemäß Artikel 21 DSGVO  
Hinweis: Nutzer können der Verarbeitung ihrer personenbezogenen Daten entsprechend den gesetzlichen Vorgaben jederzeit mit Wirkung für die Zukunft widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.  
Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.

***1.4 Datenlöschung und Speicherdauer***  
Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.

***1.5 Sicherheit der Verarbeitung***
1. Wir haben angemessene sowie dem Stand der Technik entsprechende technische und organisatorische Sicherheitsmaßnahmen (TOMs) umgesetzt. So werden die durch uns verarbeiteten Daten vor zufälliger oder vorsätzlicher Manipulation, Verlust, Zerstörung sowie vor unberechtigten Zugriff geschützt.
2. Zu den Sicherheitsmaßnahmen gehört insbesondere die verschlüsselte Übertragung von Daten zwischen Ihrem Browser und unserem Server.

***1.6 Datenübermittlung an Dritte, Subunternehmer und Drittanbieter***
1. Eine Übermittlung personenbezogener Daten an **Dritte** erfolgt nur im Rahmen der gesetzlichen Vorgaben. Wir geben die Daten der Nutzer nur dann an Dritte weiter, wenn dies z.B. für Abrechnungszwecke erforderlich ist oder für andere Zwecke, wenn die Übermittlung erforderlich ist, um vertragliche Verpflichtungen gegenüber den Nutzern zu erfüllen.
2. Sofern wir **Subunternehmer** für unser Online-Angebot einsetzen, haben wir gegenüber diesen Unternehmen geeignete vertragliche Vorkehrungen sowie entsprechende technische und organisatorische Maßnahmen getroffen.
3. Sofern wir Inhalte, Werkzeuge oder sonstige Mittel von anderen Unternehmen (nachfolgend gemeinsam als „**Drittanbieter**“ bezeichnet) verwenden und deren genannter Sitz sich in einem Drittland befindet, ist davon auszugehen, dass ein Datentransfer in die Sitzstaaten der Drittanbieter stattfindet. Die Übermittlung personenbezogener Daten in Drittstaaten durch uns erfolgt ausschließlich nur dann, wenn ein angemessenes Datenschutzniveau, eine Einwilligung der Nutzer oder sonst eine gesetzliche Erlaubnis vorliegt.

**2 Konkrete Datenverarbeitung**  
***2.1	Erhebung von Informationen zur Nutzung des Online-Angebotes***
1. Bei der Nutzung des Online-Angebotes werden Informationen automatisch von dem Browser des Nutzers an uns übermittelt; dazu gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
2. Die Verarbeitung dieser Informationen erfolgt aufgrund berechtigter Interessen gemäß Artikel 6 Absatz (1) lit. f DSGVO (z.B. Optimierung des Online-Angebotes) sowie zur Gewährleistung der Sicherheit der Verarbeitung gemäß Artikel 5 Absatz (1) lit. f DSGVO (z.B. zur Abwehr und Aufklärung von Cyberangriffen).
3. Die Informationen werden automatisch 4 Wochen nach dem Ende der Verbindung – d.h. Nutzung des Online-Angebotes - gelöscht, sofern dem keine anderweitigen Aufbewahrungsfristen entgegenstehen.
4. Die Erfassung der Daten und die Speicherung der Daten in Logfiles ist für die Bereitstellung des Online-Angebotes zwingend erforderlich. Es besteht daher seitens des Nutzers keine Lösch-, Widerspruchs- oder Berichtigungsmöglichkeit.
5. Die Aufklärung der Mitarbeiter über die Verarbeitung personenbezogener Daten im Rahmen der Nutzung von Fairmas Online Produkten obliegt dem jeweiligen Fairmas Kunden in seiner Funktion als Arbeitgeber.

***2.2 Microsoft Application Insights***
1. Unsere Webanwendungen benutzen Azure Apllication Insights, einen Dienst von Microsoft, der uns beim Optimieren der Leistung und Benutzerfreundlichkeit unserer Anwendungen unterstützt. Er überwacht die Anwendung während der Ausführung und erstellt Diagramme und Tabellen, die beispielsweise Aufschluss darüber geben, zu welchen Tageszeiten die Benutzung groß ist oder wie gut die App reagiert. Im Falle von Abstürzen, Fehlern oder Leistungsproblemen können Sie Telemetriedaten helfen, um Fehlerursachen zu ermitteln. Während der App-Ausführung überwacht Apllication Insights den Betrieb und sendet Telemetriedaten an einen Application Insights-Dienst (ein von Microsoft Azure gehosteten Clouddienst)
2. Der Großteil der standardmäßigen Telemetrie (d. h. Telemetrie, die ohne das Schreiben von Code gesendet wird) enthält keine expliziten personenbezogenen Informationen. Allerdings können möglicherweise Rückschlüsse aus einer Ereignisauflistung auf einzelne Personen gezogen werden.
3. Weitere Informationen finden Sie in den Datenschutzhinweisen von Microsoft Application Insights https://docs.microsoft.com/de-de/azure/application-insights/app-insights-data-retention-privacy

***2.3 Kontaktformular und Kontaktaufnahme per E-Mail***
1. Bei der Kontaktaufnahme mit uns (per Online-Formular oder E-Mail) werden die vom Nutzer bereitgestellten Daten ausschließlich zur Bearbeitung der Anfrage und deren Abwicklung verarbeitet.
2. Eine anderweitige Nutzung der Daten erfolgt nur auf Basis einer Einwilligung durch den Nutzer.
3. Die Daten der Nutzer werden in unserem Customer-Relationship-Management System ("CRM System") oder einer vergleichbaren Software/Datenbank gespeichert. Es gelten die gesetzlichen Aufbewahrungsfristen für Geschäftsbriefe.

**3 Cookie-Policy**  
***3.1 Allgemeine Informationen***
1. Cookies sind Informationen, die von unserem Webserver oder Webservern Dritter an die Web-Browser der Nutzer übertragen und dort für einen späteren Abruf gespeichert werden. Bei Cookies kann es sich um kleine Dateien oder sonstige Arten der Informationsspeicherung handeln.
2. Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Online-Angebotes führen.

***3.2 Widerspruchsmöglichkeiten***
Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, widersprechen über  
a. die Deaktivierungsseite der Netzwerkwerbeinitiative: [http://optout.networkadvertising.org/](http://optout.networkadvertising.org/)  
b. die US-amerikanische Webseite [http://www.aboutads.info/choices](http://www.aboutads.info/choices)  
c. die europäische Webseite [http://www.youronlinechoices.com/uk/your-ad-choices/](http://www.youronlinechoices.com/uk/your-ad-choices/)

**4 Änderungen der Datenschutzerklärung**
1. Wir behalten uns vor, diese Datenschutzerklärung in Bezug auf die Datenverarbeitung zu ändern, um sie an geänderte Rechtslagen, an Änderungen des Online-Angebotes oder an der Datenverarbeitung anzupassen.
2. Sofern Einwilligungen der Nutzer erforderlich sind oder Bestandteile der Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den Nutzern enthalten, erfolgen die Änderungen nur mit Zustimmung der Nutzer.
3. Die Nutzer werden gebeten sich regelmäßig über den Inhalt dieser Datenschutzerklärung zu informieren.











Stand: 29.05.2018
`

