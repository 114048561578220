import { ICheckVerificationModel } from '../models/ICheckVerificationModel';
import { VerificationTypeEnum } from '../models/VerificationTypeEnum';
import { IResetPasswordModel } from '../models/IResetPasswordModel';
import { ICheckVerificationResult } from '../models/ICheckVerificationResult';
import { IPasswordResetResult } from '../models/IPasswordResetResult';
import axios from "axios";
import { Settings } from "../settings/Settings";

export class PasswordActions {
  static async getVerificationCodeValidation(verificationCode: string, verificationType: VerificationTypeEnum): Promise<
    ICheckVerificationResult
  > {
    var model: ICheckVerificationModel = {
      verificationCode,
      verificationType
    }

    var response = await axios.post<ICheckVerificationResult>(
      Settings.idsUrl + "/api/CheckVerification",
      model,
      { withCredentials: true }
    );

    return response.data;
  }

  static async resetPassword(
    verificationCode: string,
    verificationType: VerificationTypeEnum,
    newPassword: string,
    redirectUrl: string
  ): Promise<IPasswordResetResult> {
    const model: IResetPasswordModel = {
      verificationCode,
      verificationType,
      newPassword,
      redirectUrl
    }

    var response = await axios.post(
      Settings.idsUrl + "/api/ResetPassword",
      model,
      { withCredentials: true }
    );

    return response.data
  }
}
