import { makeStyles } from "@material-ui/core";
import React, { Dispatch } from "react";
import { BodyThemeEnum } from "../../../common/Enums";
import { Utils } from "../../../_core/Utils";
import TranslationContext from "../../TranslationContext";

const lightOrDarkCondition: BodyThemeEnum = Utils.getTheme();

const useStyles = makeStyles({
  loginEmailInput: {
    "&::placeholder": {
      color: lightOrDarkCondition === "dark" ? "#4A4A4A" : "#C0C2CE",
    },
  },
});

interface IEnterLoginProps {
  loginEmail: string;
  tabIndex: number;
  setLoginName?: Dispatch<any>;
  isReadOnly?: boolean;
  onEnter?: () => void;
}

const EnterLoginEmail: React.FunctionComponent<IEnterLoginProps> = (props) => {
  const translations = React.useContext(TranslationContext);
  const { tabIndex, isReadOnly, setLoginName, onEnter } = props;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoginName(event.target.value);
    },
    [setLoginName]
  );

  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.nativeEvent.code === "Enter" && onEnter) {
        onEnter();
      }
    },
    [onEnter]
  );

  const classes = useStyles(lightOrDarkCondition);

  return (
    <div className="form-item">
      <input
        id="email"
        className={classes.loginEmailInput}
        value={props.loginEmail}
        onChange={handleChange}
        name="email"
        type="email"
        placeholder={translations.placeholderEnterEmail}
        autoFocus={true}
        tabIndex={tabIndex}
        readOnly={isReadOnly}
        onKeyUp={handleKeyUp}
        autoComplete={"username"}
      />     
    </div>
  );
};

export default EnterLoginEmail;
