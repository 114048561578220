import React from "react";
import PageFooter from "../components/PageBody/PageFooter";
import EnterLoginEmail from "../components/PageBody/Parts/EnterLoginName";
import { Link } from "react-router-dom";
import axios from "axios";
import { Settings } from "../_core/settings/Settings";
import { Utils } from "../_core/Utils";
import { IPasswordForgotModel } from "../_core/models/IPasswordForgotModel";
import TranslationContext from "../components/TranslationContext";
import SetRandomScreen from "../common/SetRandomScreen";
import { BodyClassEnum } from "../common/Enums";
import Header from "../components/PageBody/Parts/Header";

const ForgotPasswordPage: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const [showForm, setShowForm] = React.useState<boolean>(true);
  const [loginEmail, setLoginEmail] = React.useState<string>("");

  async function sendPassword() {
    setShowForm(false);
    const passwordForgotModel: IPasswordForgotModel = {
      email: loginEmail,
      returnUrl: Utils.getReturnUrlFromLocation(window.location),
      locationSearch: window.location.search,
    };
    await axios.post(
      Settings.idsUrl + "/api/forgotpassword",
      passwordForgotModel,
      { withCredentials: true }
    );
  }

  return (
    <div id="content-container">
      <section className="content single-input-field-width single-input-field single-input-field-email single-input-field-label">
        {showForm ? (
          <Header headerResourceKey="labelForgotPasswordEmail" />
        ) : (
          <Header
            headerResourceKey="captionThankYou"
            textResourceKey="descriptionCheckYourEmail"
          />
        )}
        {showForm && (
          <div className="form">
            <div className="form-items">
              <div className="action-hint">{translations.labelPleaseLogin}</div>
              <EnterLoginEmail
                loginEmail={loginEmail}
                setLoginName={setLoginEmail}
                tabIndex={1}
              />
            </div>
            <div className="action">
              <button
                className="btn btn-login"
                type="button"
                tabIndex={2}
                onClick={sendPassword}
              >
                {translations.labelReset}
              </button>
            </div>
          </div>
        )}
        <PageFooter additionalFooterContent={<AdditionalFooterContent />} />
      </section>
    </div>
  );
};

const AdditionalFooterContent: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const targetLocation = "/" + window.location.search;

  return (
    <div className="link-login">
      <Link to={targetLocation}>
        <button className="btn btn-link" type="button">
          {translations.labelRememberedPassword}
        </button>
      </Link>
    </div>
  );
};

export default SetRandomScreen(
  ForgotPasswordPage,
  BodyClassEnum.ForgotPassword
);
