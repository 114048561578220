export class PasswordPolicy {
  /**
   * Password restrictions. Keep in sync with Fairmas.IdentityServer.BL\Configuration\DefaultPasswordPolicyConfiguration.cs.
   */
  static minLength = 12;
  static validationRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(.*)$/;

  static check(password: string) {
    if (password.length < PasswordPolicy.minLength) {
      return false;
    }

    var isValidRegex = new RegExp(PasswordPolicy.validationRegex).test(
      password
    );

    if (isValidRegex) {
      return true;
    }

    return false;
  }
}
