import React from "react";
import { PageBody } from "./components/PageBody/PageBody";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { TranslationProvider } from "./components/TranslationContext";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./common/Theme";
import "./sass/animation.scss";
import axios from "axios";
import { Settings } from "./_core/settings/Settings";

async function getCsrfToken() {
  // Request the csrf token.
  return await axios.get(Settings.idsUrl + "/api/getcsrftoken", {
    withCredentials: true,
  });
}

function App() {
  const [csrfTokenLoaded, setCsrfTokenLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!csrfTokenLoaded) {
      getCsrfToken().then((response) => {
        // Tell axios to send the csrf token as a header value with future request.
        axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data;
        setCsrfTokenLoaded(true);
      });
    }
  }, [csrfTokenLoaded]);

  if (!csrfTokenLoaded) {
    return null;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <TranslationProvider>
          <PageBody />
        </TranslationProvider>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
