import React from "react";
import { Route, Switch } from "react-router-dom";
import LogoutPage from "../../pages/Logout";
import ForgotPasswordPage from "../../pages/ForgotPassword";
import ErrorPage from "../../pages/Error";
import ResetPassword from "../../pages/ResetPassword";
import ExpiredLinkPage from "../../pages/ExpiredLink";
import Locked from "../../pages/Locked";
import Unlock from "../../pages/Unlock";
import VerifyNewEmailPage from "../../pages/VerifyNewEmailPage";
import LoginContainer from "../../pages/LoginContainer";
import FallbackLandingPage from "../../pages/FallbackLandingPage";

export function PageBody() {
  return (
    <Switch>
      <Route path="/logout/" component={LogoutPage} />
      <Route path="/forgotPassword/" component={ForgotPasswordPage} />
      <Route path="/resetPassword/" component={ResetPassword} />
      <Route path="/expiredLink/" component={ExpiredLinkPage} />
      <Route path="/error/" component={ErrorPage} />
      <Route path="/locked/" component={Locked} />
      <Route path="/unlock/" component={Unlock} />
      <Route path="/verifyNewEmail/" component={VerifyNewEmailPage} />
      <Route exact path="/" component={LoginContainer} />
      <Route component={FallbackLandingPage} />
    </Switch>
  );
}
