import axios from "axios";
import { Translations } from "./Translations";
import { Settings } from "../settings/Settings";

const apiTranslationResource = `${Settings.idsUrl}/api/i18n/getresource`;

export class TranslationsRepo {
  static async Get(languageCode: string): Promise<Translations> {
    const url = `${apiTranslationResource}?requestedLanguageCode=${languageCode}`;
    const result = await axios.get<Translations>(url, {
      withCredentials: true,
    });

    return result.data;
  }
}
