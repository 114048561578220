import React from "react";
import PageFooter from "../components/PageBody/PageFooter";
import { Settings } from "../_core/settings/Settings";
import TranslationContext from "../components/TranslationContext";
import { VerifyNewEmail } from "../_core/auth/VerifyNewEmail";
import { Utils } from "../_core/Utils";
import SetRandomScreen from "../common/SetRandomScreen";
import { BodyClassEnum } from "../common/Enums";

const VerifyNewEmailPage: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const [caption, setCaption] = React.useState<string>("");

  React.useEffect(() => {
    const verificationCode = window.location.pathname.split("/").pop() || "";
    const redirectUrl = Utils.getRedirectUrlFromLocation(window.location);

    VerifyNewEmail.verify(verificationCode, redirectUrl).then(
      (result) => {
        if (result.success) {
          // show success, redirect to login
          setCaption(
            translations.captionVerifyNewEmailSuccessful.replace(
              "{username}",
              result.userFirstName
            )
          );
          setTimeout(() => {
            window.location.href = result.redirectUrl;
          }, Settings.redirectDelay);
        } else {
          setCaption(translations.captionSomethingWrong);
        }
      },
      (_error) => {
        setCaption(translations.captionSomethingWrong);
      }
    );
  }, [
    translations.captionVerifyNewEmailSuccessful,
    translations.captionSomethingWrong,
  ]);

  return (
    <div id="content-container">
      <section className="content">
        <h1>{caption}</h1>
        <PageFooter />
      </section>
    </div>
  );
};

export default SetRandomScreen(
  VerifyNewEmailPage,
  BodyClassEnum.EmailVerification
);
