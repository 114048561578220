import { IAuthenticationResult } from './../models/IAuthenticationResult';
import { Utils } from './../Utils';
import axios, { AxiosResponse } from "axios";
import { Settings } from "../settings/Settings";


export class Login {

  static get defaultProductName() {
    return "Fairmas"
  }

  static async try(
    userName: string,
    password: string,
    location: Location
  ): Promise<ILoginResult> {
    try {
      const returnUrl = Utils.getReturnUrlFromLocation(location)
      const response = await axios.post<IAuthenticationResult>(
        Settings.idsUrl + "/api/login",
        {
          Username: userName,
          Password: password,
          ReturnUrl: returnUrl
        },
        { withCredentials: true }
      );

      if (response.data && response.data.isAuthenticated) {
        return new LoginResultSuccess(response.data.redirectUrl);
      }

      return new LoginResultFailed(response);
    } catch (error) {
      return new LoginResultFailed(null);
    }
  }

  static getProduct(): string
  {
    let product = ""

    try {
      const returnUrl = Utils.getUrlParameterByName("ReturnUrl") 
      const parsedReturnUrl = new URL(decodeURIComponent(returnUrl));
      const urlParameters = new URLSearchParams(parsedReturnUrl.search);
      product = urlParameters.get('product') || " ";
    } catch (error) {
      console.error(error)
    }

    if (product === "") {
      product = Login.defaultProductName
    }

    return product
  }
}

export interface ILoginResult {
  isSuccess: boolean;
  redirectUrl: string;
  externalIdentityProviderGuid: string;
  userEmail: string,
  response: AxiosResponse<IAuthenticationResult> | null;
}

export interface ICheckUsernameResult {
  externalIdentityProviderGuid: string,
  returnUrl: string,
  userHasExternalIdentityProvider: boolean
}

abstract class LoginResult implements ILoginResult {
  isSuccess: boolean = false;
  redirectUrl: string = "";
  externalIdentityProviderGuid: string = "";
  userEmail: string = "";
  response: AxiosResponse<IAuthenticationResult> | null = null;
}

class LoginResultSuccess extends LoginResult {
  constructor(redirectUrl: string) {
    super();
    this.isSuccess = true;
    this.redirectUrl = redirectUrl;
  }
}

class LoginResultFailed extends LoginResult {
  constructor(response: AxiosResponse<IAuthenticationResult> | null) {
    super();
    this.response = response;
  }
}

