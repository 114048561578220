import React from "react";
import FallbackLandingPage from "./FallbackLandingPage";
import LoginPage from "./Login";

const isUrlValid = (): boolean => {
  const urlParams = window.location.search;
  const parsedParams = new URLSearchParams(urlParams);
  const returnUrlParamValue = parsedParams.get("ReturnUrl");

  return returnUrlParamValue?.length > 0;
};

const LoginContainer: React.FunctionComponent = () => {
  const isLoginCorrect = React.useMemo((): boolean => {
    return isUrlValid();
  }, []);

  if (isLoginCorrect) {
    return <LoginPage />;
  }

  return <FallbackLandingPage />;
};

export default LoginContainer;
