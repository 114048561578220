import React from "react";
import { BodyClassEnum } from "../common/Enums";
import SetRandomScreen from "../common/SetRandomScreen";

const ErrorPage: React.FunctionComponent = () => {
  return (
    <div id="content-container">
      <section className="content multi-input-field">
        <header>
          <div className="fairmas-logo">
            <img src="/images/fairmas-logo.svg" alt="Fairmas Logo" />
          </div>
          <div className="header-text">
            <h1>We're sorry!</h1>
            <h2>Something went wrong.</h2>
          </div>
        </header>
      </section>
    </div>
  );
};

export default SetRandomScreen(ErrorPage, BodyClassEnum.UnknownUser, false);
