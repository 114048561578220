import React from "react";
import TranslationContext from "../components/TranslationContext";
import { Login } from "../_core/auth/Login";
import PageFooter from "../components/PageBody/PageFooter";
import { makeStyles } from "@material-ui/core";
import { Translations } from "../_core/translations/Translations";
import SetRandomScreen from "../common/SetRandomScreen";
import { BodyClassEnum } from "../common/Enums";
import Header from "../components/PageBody/Parts/Header";

const useStyles = makeStyles({
  square: {
    listStyleType: "square",
  },
});

/**
 *
 * @returns A component that is rendered when the URL of the Login page doesn't have the correct format
 */
const FallbackLandingPage: React.FunctionComponent = () => {
  const translations: Translations = React.useContext(TranslationContext);
  const url: string = window.location.host;
  const product = Login.defaultProductName;
  const descriptionIncorrectAccess: string =
    translations.descriptionIncorrectAccess;
  const urlSuggestion: string =
    translations.descriptionAccessSuggestion1.replace("{url}", url);
  const suggestions: string[] = [
    urlSuggestion,
    translations.descriptionAccessSuggestion2,
    translations.descriptionAccessSuggestion3,
  ];

  const classes = useStyles();

  return (
    <div id="content-container">
      <section className="content multi-input-field">
        <Header
          headerResourceKey="captionLoginWelcome"
          replacePlaceHolder={(source) => {
            return source.replace("{product}", product);
          }}
        />
        <section className="explanation">
          <p>{descriptionIncorrectAccess}</p>
          <ul className={classes.square}>
            {suggestions.map((suggestion, index) => {
              return <li key={index}>{suggestion}</li>;
            })}
          </ul>
        </section>
        <PageFooter />
      </section>
    </div>
  );
};

export default SetRandomScreen(
  FallbackLandingPage,
  BodyClassEnum.FallbackLandingPage
);
