import { BodyThemeEnum } from "../common/Enums";
import { Settings } from "./settings/Settings";

export class Utils {
  static getCookieValue(a: string): string {
    var b = document.cookie.match("(^|[^;]+)\\s*" + a + "\\s*=\\s*([^;]+)");
    return (b ? b.pop() : "") as string;
  }

  static getUrlParameterByName(name: string): string {
    var url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return "";
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static getParameterFromReturnUrl(name: string): string {
    const returnUrl = this.getReturnUrlFromLocation(window.location);
    const parsedReturnUrl = new URL(returnUrl);
    const urlParameters = new URLSearchParams(parsedReturnUrl.search);

    return urlParameters.get("product") || "Fairmas";
  }

  /**
   * Extracts the return url (without the host name) for the login model
   * @param location The window.location
   */
  static getReturnUrlFromLocation(
    location: Location,
    removeIdsBaseUrl: boolean = true
  ): string {
    if (location.search.indexOf("&") > 0) {
      return ""; //we expect only one parameter "ReturnUrl"
    }

    const urlParams = new URLSearchParams(location.search);
    const returnUrl =
      urlParams.get("ReturnUrl") || urlParams.get("returnUrl") || "";

    if (returnUrl === "") {
      return "";
    }

    const decodedReturnUrl = decodeURIComponent(returnUrl);

    if (removeIdsBaseUrl) {
      return decodedReturnUrl.substring(Settings.idsUrl.length);
    }

    return decodedReturnUrl;
  }

  /**
   * Extracts the redirect url
   * @param location The window.location
   */
  static getRedirectUrlFromLocation(location: Location): string {
    if (location.search.indexOf("&") > 0) {
      return ""; //we expect only one parameter "ReturnUrl"
    }

    const urlParams = new URLSearchParams(location.search);
    const redirectUrl =
      urlParams.get("RedirectUrl") || urlParams.get("redirectUrl") || "";

    if (redirectUrl === "") {
      return "";
    }

    const result = decodeURIComponent(redirectUrl);

    return result;
  }

  /**
   * Redirects the user to the redirect url if the current url search params
   * have a parameter "RedirectUrl".
   */
  static redirectToSearchParamsRedirectUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("RedirectUrl");
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  static isValidHttpUrl(urlString: string): boolean {
    let url;

    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  /**
   * Gets the dark or light theme for the login screens
   */
  static getTheme(): BodyThemeEnum {
    const dayTime = new Date().getHours();
    const lightOrDarkTheme =
      dayTime < 18 ? BodyThemeEnum.Dark : BodyThemeEnum.Light;
    return lightOrDarkTheme;
  }
}
