import React from "react";
import PageFooter from "../components/PageBody/PageFooter";
import { Link } from "react-router-dom";
import TranslationContext from "../components/TranslationContext";
import { Utils } from "../_core/Utils";
import SetRandomScreen from "../common/SetRandomScreen";
import { BodyClassEnum } from "../common/Enums";
import Header from "../components/PageBody/Parts/Header";

const ExpiredLinkPage: React.FunctionComponent = () => {
  return (
    <div id="content-container">
      <section className="content">
        <Header headerResourceKey="captionPasswordLinkExpired" />
        <PageFooter
          additionalFooterContent={<AdditionalFooterContent />}
          useStackedLinks={true}
        />
      </section>
    </div>
  );
};

const AdditionalFooterContent: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const forgotPasswordTargetLocation =
    "/forgotpassword" + window.location.search;
  const redirectLocation = Utils.getRedirectUrlFromLocation(window.location);

  return (
    <div className="link-login forgotten">
      <div></div>
      <Link to={forgotPasswordTargetLocation} className="btn btn-link">
        {translations.labelForgotPassword}
      </Link>
      <a href={redirectLocation} className="btn btn-link">
        {translations.labelGotoLogin}
      </a>
    </div>
  );
};

export default SetRandomScreen(ExpiredLinkPage, BodyClassEnum.ExpiredLink);
