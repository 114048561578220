// Keep in syc with Fairmas.IdentityServer.BL.DomainModels.VerificationType
export enum VerificationTypeEnum {
    None = 0,
    PasswordReset = 1,
    PasswordExpired = 2,
    LockedAfterTooManyFailedAttempts = 3,
    ContactEmailChanged = 4,
    UserNotFound = 5,
    VerifyUser = 6,
    EmailChanged = 7
}