import React from "react";
import EnterLoginEmail from "../components/PageBody/Parts/EnterLoginName";
import EnterPassword from "../components/PageBody/Parts/EnterPassword";
import PageFooter from "../components/PageBody/PageFooter";
import { ICheckUsernameResult, ILoginResult, Login } from "../_core/auth/Login";
import { Link } from "react-router-dom";
import { VerificationTypeEnum } from "../_core/models/VerificationTypeEnum";
import TranslationContext from "../components/TranslationContext";
import { Settings } from "../_core/settings/Settings";
import DisplayOnlyInput from "../components/PageBody/Parts/DisplayOnlyInput";
import axios, { AxiosResponse } from "axios";
import { Utils } from "../_core/Utils";
import { BodyClassEnum, BodyThemeEnum } from "../common/Enums";
import SetRandomScreen from "../common/SetRandomScreen";
import { PageCssTheme } from "./PageCssTheme";
import Header from "../components/PageBody/Parts/Header";

const lightOrDarkCondition: BodyThemeEnum = Utils.getTheme();

const AdditionalFooterContent: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const targetLocation = "/forgotpassword" + window.location.search;

  return (
    <div className="link-login forgotten">
      <Link to={targetLocation}>
        <button className="btn btn-link" type="button" tabIndex={20}>
          {translations.labelForgotPassword}
        </button>
      </Link>
    </div>
  );
};

const LoginPage: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const product = Login.getProduct();

  const [loginName, setLoginName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [showPasswordScreen, setShowPasswordScreen] =
    React.useState<boolean>(false);
  const [loginFailed, setLoginFailed] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const login = React.useCallback(async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const result: ILoginResult = await Login.try(
      loginName,
      password,
      window.location
    );
    setIsLoading(false);

    if (result.isSuccess) {
      window.location.href = Settings.idsUrl + result.redirectUrl; // todo [FairIdentity]: remove hard coded url
    } else {
      if (result.response) {
        switch (result.response.data.verificationType) {
          case VerificationTypeEnum.PasswordExpired:
            const resetPasswordUrl =
              "/ResetPassword/" +
              VerificationTypeEnum.PasswordExpired +
              "/" +
              result.response.data.verificationCode +
              window.location.search;
            window.location.href = resetPasswordUrl;
            break;
          case VerificationTypeEnum.LockedAfterTooManyFailedAttempts:
            const lockedUrl = "/locked" + window.location.search;
            window.location.href = lockedUrl;
            break;
          case VerificationTypeEnum.None:
            PageCssTheme.setBackgroundImage(
              BodyClassEnum.WrongPasswordUnknownUser,
              lightOrDarkCondition
            );
            setLoginFailed(true);
            break;
          default:
            break;
        }
      } else {
        PageCssTheme.setBackgroundImage(
          BodyClassEnum.WrongPasswordUnknownUser,
          lightOrDarkCondition
        );
        setLoginFailed(true);
      }
    }
  }, [isLoading, loginName, password]);

  const handleUserName = React.useCallback(async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const result: AxiosResponse<ICheckUsernameResult> = await axios.post(
      Settings.idsUrl + "/api/CheckUsername",
      {
        Username: loginName,
        ReturnUrl: Utils.getReturnUrlFromLocation(window.location),
      },
      { withCredentials: true }
    );
    setIsLoading(false);

    if (result.data.userHasExternalIdentityProvider) {
      const url = new URL(
        Settings.idsUrl + "/api/RedirectToExternalIdentityProvider"
      );
      url.searchParams.append("returnUrl", result.data.returnUrl);
      url.searchParams.append(
        "externalIdentityProviderGuid",
        result.data.externalIdentityProviderGuid
      );
      url.searchParams.append("login_hint", loginName);
      window.location.href = url.toString();
    } else {
      setShowPasswordScreen(true);
    }
  }, [isLoading, loginName]);

  const onHandleBackClick = React.useCallback(() => {
    setLoginFailed(false);
    setShowPasswordScreen(false);
    setPassword("");
  }, []);

  if (showPasswordScreen) {
    return (
      <div id="content-container">
        <section className="content multi-input-field">
          {loginFailed ? (
            <Header headerResourceKey="captionLoginWrongPasswordUnknownUser" />
          ) : (
            <Header
              headerResourceKey="captionLoginWelcome"
              subHeaderResourceKey="captionPlanYourSuccess"
              replacePlaceHolder={(source) => {
                return source.replace("{product}", product);
              }}
            />
          )}

          <div className="form">
            <div className="form-items">
              <div className="action-hint">{translations.labelPleaseLogin}</div>
              <div className="form-input-items">
                <DisplayOnlyInput
                  text={loginName}
                  tabIndex={1}
                  onHandleBackClick={onHandleBackClick}
                />
                <EnterPassword
                  password={password}
                  onChange={setPassword}
                  onEnter={login}
                  tabIndex={2}
                  autofocus={true}
                  showPasswordButton={true}
                />
              </div>
            </div>
            <div className="action">
              <button
                className="btn btn-login"
                type="button"
                tabIndex={3}
                onClick={login}
              >
                {translations.labelLogin}
              </button>
            </div>
          </div>
          <PageFooter additionalFooterContent={<AdditionalFooterContent />} />
        </section>
      </div>
    );
  }

  return (
    <div id="content-container">
      <section className="content single-input-field">
        <Header
          headerResourceKey="captionLoginWelcome"
          subHeaderResourceKey="captionPlanYourSuccess"
          replacePlaceHolder={(source) => {
            return source.replace("{product}", product);
          }}
        />
        <div className="form">
          <div className="form-items">
            <div className="action-hint">{translations.labelPleaseLogin}</div>
            <div className="form-input-items">
              <EnterLoginEmail
                loginEmail={loginName}
                setLoginName={setLoginName}
                tabIndex={2}
                onEnter={handleUserName}
              />
            </div>
          </div>
          <div className="action">
            <button
              className="btn btn-login"
              type="button"
              tabIndex={3}
              onClick={handleUserName}
            >
              {translations.labelNext}
            </button>
          </div>
        </div>
        <PageFooter additionalFooterContent={<AdditionalFooterContent />} />
      </section>
    </div>
  );
};

export default SetRandomScreen(LoginPage, BodyClassEnum.UnknownUser, true);
