import React from "react";
import PageFooter from "../components/PageBody/PageFooter";
import { UnlockUser } from "../_core/auth/UnlockUser";
import { Settings } from "../_core/settings/Settings";
import TranslationContext from "../components/TranslationContext";
import { Utils } from "../_core/Utils";
import { Link } from "react-router-dom";
import { BodyClassEnum } from "../common/Enums";
import SetRandomScreen from "../common/SetRandomScreen";
import Header from "../components/PageBody/Parts/Header";

interface IViewModel {
  headerResourceKey: string;
  textResourceKey: string;
  userName: string;
}

const Unlock: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const [viewModel, setViewModel] = React.useState<IViewModel>({
    headerResourceKey: "captionHey",
    textResourceKey: "captionUnlockSuccessful",
    userName: "",
  });

  React.useEffect(() => {
    const verificationCode = window.location.pathname.split("/").pop() || "";
    const redirectUrl = Utils.getRedirectUrlFromLocation(window.location);

    UnlockUser.unlock(verificationCode, redirectUrl).then((result) => {
      if (result.success) {
        // show success, redirect to login
        setViewModel({
          headerResourceKey: "captionGreatJobUsername",
          textResourceKey: "captionUnlockSuccessful",
          userName: result.userName,
        });
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, Settings.redirectDelay);
      } else if (result.linkExpired) {
        setViewModel({
          headerResourceKey: "captionHey",
          textResourceKey: "captionUnlockLinkExpired",
          userName: "",
        });
      } else {
        setViewModel({
          headerResourceKey: "captionSomethingWrong",
          textResourceKey: null,
          userName: "",
        });
      }
    });
  }, [
    translations.captionUnlockSuccessful,
    translations.captionSomethingWrong,
    translations.captionUnlockLinkExpired,
  ]);

  return (
    <div id="content-container">
      <section className="content">
        <Header
          headerResourceKey={viewModel.headerResourceKey}
          textResourceKey={viewModel.textResourceKey}
          replacePlaceHolder={(source) => {
            return source.replace("{username}", viewModel.userName);
          }}
        />
        <PageFooter
          additionalFooterContent={<AdditionalFooterContent />}
          useStackedLinks={true}
        />
      </section>
    </div>
  );
};

const AdditionalFooterContent: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const forgotPasswordTargetLocation =
    "/forgotpassword" + window.location.search;
  const redirectLocation = Utils.getRedirectUrlFromLocation(window.location);

  return (
    <div className="link-login forgotten">
      <Link to={forgotPasswordTargetLocation} className="btn btn-link">
        <button className="btn btn-link">
          {translations.labelForgotPassword}
        </button>
      </Link>
      <a href={redirectLocation} className="btn btn-link">
        <button className="btn btn-link">{translations.labelLogin}</button>
      </a>
    </div>
  );
};

export default SetRandomScreen(Unlock, BodyClassEnum.Unlock);
