import React from "react";
import PageFooter from "../components/PageBody/PageFooter";
import { Settings } from "../_core/settings/Settings";
import TranslationContext from "../components/TranslationContext";
import { Logout } from "../_core/auth/Logout";
import { BodyClassEnum } from "../common/Enums";
import SetRandomScreen from "../common/SetRandomScreen";
import Header from "../components/PageBody/Parts/Header";

const LogoutPage: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);
  const [caption, setCaption] = React.useState<string>("");
  const [username, setUsername] = React.useState<string>("");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const logoutId = urlParams.get("logoutId") || "";

    Logout.logout(logoutId)
      .then((result) => {
        setCaption("captionLogoffUser");
        setUsername(result.username);
        setTimeout(() => {
          if (result.postLogoutRedirectUri) {
            window.location.href = result.postLogoutRedirectUri;
          }
        }, Settings.logoutRedirectDelay);
      })
      .catch(() => {
        window.location.href = "/Error";
      });
  }, [translations.captionLogoffUser]);

  return (
    <div id="content-container">
      {caption && (
        <section className="content">
          <Header
            headerResourceKey={caption}
            replacePlaceHolder={(source) => {
              return source.replace("{username}", username);
            }}
          />
          <PageFooter />
        </section>
      )}
    </div>
  );
};

export default SetRandomScreen(LogoutPage, BodyClassEnum.UserLogOff);
