import { createTheme, Theme } from "@material-ui/core";
/**
 * Breakpoints created so we can use media queries inside the MUI styling hooks
 * These break points are the same as the ones set in the _breakpoints.scss file
 */
const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1400,
      xl: 1600,
    },
  },
});

export default theme;
