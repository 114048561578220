import React, { useState } from "react";
import { Translations } from "../_core/translations/Translations";
import { TranslationsRepo } from "../_core/translations/TranslationsRepo";

/**
 * Provides the translations.
 */
const TranslationContext = React.createContext(new Translations());

interface IProps {
  children?: React.ReactNode;
}

/**
 * The context provider for translations.
 * Should be wrapped around highest level (App) component.
 * Prevents rendering of children until translations are loaded.
 */
const TranslationProvider: React.FunctionComponent = (props: IProps) => {
  // Context state
  const [translations, setTranslations] = useState(new Translations());
  const [hasLoaded, setHasLoaded] = useState(false);

  const languageCode = navigator.language;

  React.useEffect(() => {
    TranslationsRepo.Get(languageCode).then((result) => {
      setTranslations(result);
      setHasLoaded(true);
    });
  }, [languageCode]);

  if (!hasLoaded) {
    return null;
  }

  return (
    <TranslationContext.Provider value={translations}>
      {props.children}
    </TranslationContext.Provider>
  );
};

export default TranslationContext;

export { TranslationProvider };
