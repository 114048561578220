export class Translations {
  productNameFirstPart!: string;
  productNameSecondPart!: string;

  captionLoginWelcome!: string;
  captionPlanYourSuccess!: string;
  captionLoginWrongPasswordUnknownUser!: string;
  captionLogoffUser!: string;
  captionForgotPassword!: string;
  captionThankYou!: string;
  captionResetPassword!: string;
  captionResetExpiredPassword!: string;
  captionPasswordLinkExpired!: string;
  captionLockedAfterTooManyFailedAttempts!: string;
  captionUnlockSuccessful!: string;
  captionUnlockLinkExpired!: string;
  captionResetPasswordSuccess!: string;
  captionResetPasswordHistoryViolation!: string;
  captionVerifyNewEmailSuccessful!: string;
  captionSomethingWrong!: string;
  captionHeyUsername!: string;
  captionGreatJobUsername!: string;
  descriptionLockedAfterTooManyFailedAttempts!: string;
  descriptionCheckYourEmail!: string;

  labelPleaseLogin!: string;
  labelLogin!: string;
  labelNext!: string;

  placeholderEnterEmail!: string;
  placeholderEnterPassword!: string;
  placeholderConfirmPassword!: string;

  labelHelp!: string;
  labelImprint!: string;
  labelDataPolicy!: string;
  labelForgotPassword!: string;
  labelRememberedPassword!: string;
  labelReset!: string;
  labelForgotPasswordEmail!: string;
  labelEnterNewPassword!: string;
  labelGotoLogin!: string;

  captionContact!: string;
  captionImprint!: string;
  descriptionAddress!: string;
  descriptionCommercialRegister!: string;
  descriptionCompany!: string;
  descriptionLegalHint!: string;
  descriptionLegalRepresentative!: string;
  descriptionMITLicenseComponents!: string;
  descriptionMITLicense!: string;
  descriptionMITLicensePart1!: string;
  descriptionMITLicensePart2!: string;
  descriptionIncorrectAccess!: string;
  descriptionAccessSuggestion1!: string;
  descriptionAccessSuggestion2!: string;
  descriptionAccessSuggestion3!: string;
  descriptionPasswordLengthRequirement!: string;
  descriptionPasswordPolicyRequirements!: string;

  labelAddress!: string;
  labelBackTo!: string;
  labelCommercialRegister!: string;
  labelCompany!: string;
  labelDataProtectionOfficial!: string;
  labelEmail!: string;
  labelFax!: string;
  labelIntroduction!: string;
  labelLegalHint!: string;
  labelLegalRepresentative!: string;
  labelTelephone!: string;
  labelUsedComponents!: string;
  labelVat!: string;
  labelWeb!: string;

  labelClose!: string;
  labelIntroductionPart1!: string;
  labelIntroductionPart2!: string;
  labelUsingPhone!: string;
  labelUsingEmail!: string;

  [key: string]: string;
}
