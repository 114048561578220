import React from "react";
import TranslationContext from "../../TranslationContext";

interface IHeaderProps {
  headerResourceKey: string;
  subHeaderResourceKey?: string;
  textResourceKey?: string;
  replacePlaceHolder?: (resourceString: string) => string;
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const translations = React.useContext(TranslationContext);
  const {
    headerResourceKey,
    subHeaderResourceKey,
    textResourceKey,
    replacePlaceHolder,
  } = props;

  return (
    <header>
      <div className="fairmas-logo">
        <img src="/images/fairmas-logo.svg" alt="Fairmas Logo" />
      </div>
      <div className="header-text">
        <h1>
          {replacePlaceHolder && translations[headerResourceKey]
            ? replacePlaceHolder(translations[headerResourceKey])
            : translations[headerResourceKey]}
        </h1>
        {subHeaderResourceKey && <h2>{translations[subHeaderResourceKey]}</h2>}
        {textResourceKey && (
          <p className="text">{translations[textResourceKey]}</p>
        )}
      </div>
    </header>
  );
};

export default Header;
