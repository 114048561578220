import React from "react";
import TranslationContext from "../../TranslationContext";
import { PasswordPolicy } from "../../../_core/auth/PasswordPolicy";

const PasswordPolicyComponent: React.FunctionComponent = () => {
  const translations = React.useContext(TranslationContext);

  return (
    <div className="description">
      <p className="text">
        {translations.descriptionPasswordPolicyRequirements}
      </p>
      <ul>
        <li>
          {translations.descriptionPasswordLengthRequirement.replaceAll(
            "{passwordLength}",
            PasswordPolicy.minLength.toString()
          )}
        </li>
        <li>{translations.descriptionPasswordLowerCaseRequirement}</li>
        <li>{translations.descriptionPasswordUpperCaseRequirement}</li>
        <li>{translations.descriptionPasswordNumberRequirement}</li>
        <li>{translations.descriptionPasswordSpecialCharacterRequirement}</li>
      </ul>
    </div>
  );
};

export default PasswordPolicyComponent;
